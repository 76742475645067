<template>
  <v-card-text class="pb-0">
    <p class="display-1 text--primary" style="text-align: center;">
      Per Person Pricing
    </p>

    <v-combobox
      v-model="formData.currencyCode"
      :items="currencyCodeOptions"
      label="Currency Code"
    ></v-combobox>

    <v-text-field
      v-if='((!(priceType.length === 1 && priceType.includes("Adult/Senior"))) && (!(priceType.length === 2 && priceType.includes("Receptive") && priceType.includes("Adult/Senior"))))'
      :prefix=currencySymbol v-model="formData.studentPrice" label="Student Price" type="number">
    </v-text-field>

    <span
      v-if='((!(priceType.length === 1 && priceType.includes("Adult/Senior"))) && (!(priceType.length === 2 && priceType.includes("Receptive") && priceType.includes("Adult/Senior"))))'
    >
      Student Age Range
    </span>

    <v-row
      v-if='((!(priceType.length === 1 && priceType.includes("Adult/Senior"))) && (!(priceType.length === 2 && priceType.includes("Receptive") && priceType.includes("Adult/Senior"))))'
    >
      <v-col class="px-4">
        <v-range-slider
          v-model="formData.studentAgeRange"
          :max="studentMax"
          :min="studentMin"
          hide-details
          class="align-center"
        >
          <template v-slot:prepend>
            <v-text-field
              v-model="formData.studentAgeRange[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              @change="$set(formData.studentAgeRange, 0, $event)"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              v-model="formData.studentAgeRange[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              @change="$set(formData.studentAgeRange, 1, $event)"
            ></v-text-field>
          </template>
        </v-range-slider>
      </v-col>
    </v-row>

    <v-text-field :prefix=currencySymbol v-model="formData.adultPrice" label="Adult Price" type="number">
    </v-text-field>

    <v-text-field
      v-if='((!(priceType.length === 1 && priceType.includes("Student/Youth"))) && (!(priceType.length === 2 && priceType.includes("Receptive") && priceType.includes("Student/Youth"))))'
      :prefix=currencySymbol
      v-model="formData.childPrice"
      label="Child Price"
      type="number"
    >
    </v-text-field>

    <span
      v-if='((!(priceType.length === 1 && priceType.includes("Student/Youth"))) && (!(priceType.length === 2 && priceType.includes("Receptive") && priceType.includes("Student/Youth"))))'
    >
      Child Age Range
    </span>

    <v-row
      v-if='((!(priceType.length === 1 && priceType.includes("Student/Youth"))) && (!(priceType.length === 2 && priceType.includes("Receptive") && priceType.includes("Student/Youth"))))'
    >
      <v-col class="px-4">

        <v-range-slider
          v-model="formData.childAgeRange"
          :max="childMax"
          :min="childMin"
          hide-details
          class="align-center"
        >

          <template v-slot:prepend>
            <v-text-field
              :value="formData.childAgeRange[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              @change="$set(formData.childAgeRange, 0, $event)"
            ></v-text-field>
          </template>

          <template v-slot:append>
            <v-text-field
              :value="formData.childAgeRange[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              @change="$set(formData.childAgeRange, 1, $event)"
            ></v-text-field>
          </template>

        </v-range-slider>

      </v-col>
    </v-row>

    <v-text-field
      v-if='((!(priceType.length === 1 && priceType.includes("Student/Youth"))) && (!(priceType.length === 2 && priceType.includes("Receptive") && priceType.includes("Student/Youth"))))'
      :prefix=currencySymbol
      v-model="formData.seniorPrice"
      label="Senior Price"
      type="number"
    >
    </v-text-field>

    <!-- <v-text-field
      v-if='((!(priceType.length === 1 && priceType.includes("Student/Youth"))) && (!(priceType.length === 2 && priceType.includes("Receptive") && priceType.includes("Student/Youth"))))'
      :prefix=currencySymbol
      v-model="formData.veteranPrice"
      label="Veteran Price"
      type="number"
    >
    </v-text-field> -->

    <v-text-field suffix="%" v-model="formData.tax" label="Tax" type="number"></v-text-field>
    <v-text-field suffix="%" v-model="formData.fee" label="Fee" type="number"></v-text-field>

    <v-text-field
      v-model="formData.feeIncludes"
      label="Fee Includes"
      placeholder="Ex: 18% Gratuity and 3% Admin For 21% Overall"
    >
    </v-text-field>

    <v-text-field
      v-model="formData.perPersonFee"
      :prefix=currencySymbol
      label="Per Person Fee"
      placeholder="Ex: $1.00 per person gratuity for step-on guide"
      type="number"
    >
    </v-text-field>

    <v-text-field
      v-model="formData.perPersonFeeIncludes"
      label="Per Person Fee Includes"
      placeholder="Ex: $1.00 per person guide gratuity."
    >
    </v-text-field>

    <v-text-field
      v-model="formData.groupFee"
      type="number"
      :prefix=currencySymbol
      label="Group Fee"
    >
    </v-text-field>

    <v-text-field
      v-model="formData.groupFeeIncludes"
      label="Group Fee Includes"
      placeholder="Ex: Additional group fee: $10.00 ticket mailing"
    >
    </v-text-field>

  </v-card-text>
</template>

<script>
export default {
  name: 'SinglePricing',
  props: {
    parentData: {
      type: Object,
    },
  },
  data() {
    return {
      currencyCodeOptions: [
        'Australian Dollar (AUD)',
        'British Pound (GBP)',
        'Canadian Dollar (CAD)',
        'Euro (EUR)',
        'United States Dollar (USD)',
      ],
      formData: {
        currencyCode: this.parentData.currencyCode || 'United States Dollar (USD)',
        studentPrice: this.parentData.studentPrice || '',
        studentAgeRange: this.parentData.StudentAgeRange || [0, 17],
        adultPrice: this.parentData.adultPrice || '',
        childPrice: this.parentData.childPrice || '',
        childAgeRange: this.parentData.childAgeRange || [0, 17],
        seniorPrice: this.parentData.seniorPrice || '',
        veteranPrice: this.parentData.veteranPrice || '',
        tax: this.parentData.tax || '',
        fee: this.parentData.fee || '',
        feeIncludes: this.parentData.feeIncludes || '',
        perPersonFee: this.parentData.perPersonFee || '',
        perPersonFeeIncludes: this.parentData.perPersonFeeIncludes || '',
        groupFee: this.parentData.groupFee || '',
        groupFeeIncludes: this.parentData.groupFeeIncludes || '',
      },
      studentMin: 0,
      studentMax: 17,
      studentRange: [0, 17],
      childMin: 0,
      childMax: 17,
      childRange: [0, 17],
    };
  },
  computed: {
    currencySymbol() {
      if (this.formData.currencyCode === 'Euro (EUR)') return '€';
      if (this.formData.currencyCode === 'British Pound (GBP)') return '£';
      return '$';
    },
    priceType: {
      get() {
        return this.parentData.priceType;
      },
      set(val) {
        this.value = val;
      },
    },
  },
  watch: {
    formData: {
      handler() {
        this.$emit('child-data', this.formData);
        this.priceType = this.parentData.priceType;
      },
      deep: true,
    },
  },
};
</script>
