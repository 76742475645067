<template>
  <v-card-text class="pb-0">
    <p class="display-1 text--primary" style="text-align: center;">
      Group Pricing
    </p>

    <v-combobox
      v-model="formData.currencyCode"
      :items="currencyCodeOptions"
      label="Currency Code"
    ></v-combobox>

    <v-text-field v-model="formData.groupPrice" :prefix=currencySymbol label="Group Price" type="number">
    </v-text-field>
    <v-text-field v-model="formData.tax" suffix="%" label="Tax" type="number"></v-text-field>
    <v-text-field v-model="formData.fee" suffix="%" label="Fee" type="number"></v-text-field>

    <v-text-field
      v-model="formData.feeIncludes"
      label="Fee Includes"
      placeholder="Ex: 18% Gratuity and 3% Admin For 21% Overall"
    >
    </v-text-field>

    <v-text-field
      v-model="formData.perPersonFee"
      type="number"
      :prefix=currencySymbol
      label="Per Person Fee"
      placeholder="Ex: $1.00 per person gratuity for step-on guide"
    >
    </v-text-field>

    <v-text-field
      v-model="formData.perPersonFeeIncludes"
      label="Per Person Fee Includes"
      placeholder="Ex: $1.00 per person guide gratuity."
    >
    </v-text-field>

  </v-card-text>
</template>

<script>
export default {
  name: 'GroupPricing',
  props: {
    parentData: {
      type: Object,
    },
  },
  data() {
    return {
      currencyCodeOptions: [
        'Australian Dollar (AUD)',
        'British Pound (GBP)',
        'Canadian Dollar (CAD)',
        'Euro (EUR)',
        'United States Dollar (USD)',
      ],
      formData: {
        currencyCode: this.parentData.currencyCode || 'United States Dollar (USD)',
        groupPrice: this.parentData.groupPrice || '',
        tax: this.parentData.tax || '',
        fee: this.parentData.fee || '',
        feeIncludes: this.parentData.feeIncludes || '',
        perPersonFee: this.parentData.perPersonFee || '',
        perPersonFeeIncludes: this.parentData.perPersonFeeIncludes || '',
      },
    };
  },
  computed: {
    currencySymbol() {
      if (this.formData.currencyCode === 'Euro (EUR)') return '€';
      if (this.formData.currencyCode === 'British Pound (GBP)') return '£';
      return '$';
    },
  },
  watch: {
    formData: { handler() { this.$emit('child-data', this.formData); }, deep: true },
  },
};
</script>
